body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #E0E0E0; 
}

.landing-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  height: 100vh;
}

.landing-text {
  flex: 1;
  max-width: 600px;
}

.hello-text {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.greeting-text {
  font-size: 3rem;
  font-weight: bold;
  position: relative; 
  color: #ffffff; 
}

.blinking-cursor {
  animation: blink-caret 0.75s step-end infinite; 
}

.blinking-cursor.visible {
  opacity: 1;
}

.blinking-cursor.hidden {
  opacity: 0;
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white; 
  }
}

.subheading-text {
  font-size: 1.5rem;
  margin-top: 10px;
}

.homepage-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center; 
}

.view-work, .contact-me {
  margin: 0 10px;
  padding: 12px 24px; 
  font-size: 1rem;
  color: #F08080; 
  background-color: transparent; 
  border: 2px solid #F08080; 
  border-radius: 24px; 
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.view-work:hover {
  background-color: #F08080; 
  color: #121212; 
  transform: translateY(-2px); 
}

.contact-me {
  border-color: #4A90E2; 
  color: #4A90E2; 
}

.contact-me:hover {
  background-color: #4A90E2; 
  color: #121212; 
  transform: translateY(-2px); 
}

.landing-svg {
  flex: 1; 
  display: flex;
  justify-content: flex-end; 
  align-items: center;
}

.landing-svg img {
  width: 300px;  
  height: 300px;
}

body, html {
  height: 100%;
}

#home-section {
  min-height: 100vh;
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white; 
}

.landing-content {
  font-family: "Source Code Pro", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
