#about-section {
  padding: 60px 40px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.about-content {
  font-family: "Source Code Pro", monospace;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px; 
}

.about-cards-container {
  display: flex;
  flex-grow: 1;
  gap: 30px; 
  flex-wrap: nowrap;
}

.about-card {
  background-color: #242243;
  padding: 20px; 
  border-radius: 16px; 
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3);
  flex: 1;
  min-width: 280px;
  max-width: 300px; 
  transition: transform 0.3s, box-shadow 0.3s;
  color: #FFFFFF;
}

.about-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.about-card h2 {
  font-size: 1.8rem; 
  color: #FFFFFF;
  margin-bottom: 12px;
}

.about-card p {
  font-size: 1.1rem; 
  line-height: 1.7;
  color: #C0C0C0; 
}

.skill-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.tech-flair {
  display: flex;
  align-items: center;
  padding: 8px 18px;
  background-color: transparent;
  border: 1.5px solid #FFFFFF;
  border-radius: 24px;
  transition: transform 0.3s, border-color 0.3s;
}

.tech-flair:hover {
  transform: translateY(-5px);
  border-color: #F08080;
}

.tech-flair img {
  width: 28px;
  height: 28px;
  margin-right: 8px;
}

.tech-flair span {
  font-size: 1rem;
  color: #FFFFFF;
}

.hobby-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.hobby {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1.5px solid #FFFFFF; 
  border-radius: 24px;
  padding: 8px 18px;
  margin-bottom: 10px;
  transition: transform 0.3s, border-color 0.3s;
}

.hobby:hover {
  transform: translateY(-5px);
  border-color: #F08080;
}

.hobby-title {
  font-size: 1rem;
  margin-left: 8px;
  color: #FFFFFF;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: 280px;
  max-width: 300px;
  padding-left: 20px;
  margin-left: -20px; 
  transform: scale(1.1); 
}

body {
  background: linear-gradient(to bottom, #121212, #1a1a2e); 
}

@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .about-cards-container {
    flex-direction: column;
    gap: 20px;
  }

  .animation-container {
    order: -1;
    padding-left: 0;
    margin-left: 0;
    transform: scale(1);
  }
}
